import React from 'react';
import { Form, Input, Row, Col, Tooltip } from 'antd';

import { iProfileFormData } from '../../libs/interfaces/index';
// import FormPhone from '../../components/forms/formPhone';
// import FormZip from '../../components/forms/formZip';
import { FormPhone, FormZip, FormProvince, FormCity } from '../../components/forms';
import Config from '../../config/Config';
import Images from '../../assets/images';
import './style.sass';

interface iProps {
  data: iProfileFormData;
}

const ProfileEditAdmin: React.FC<iProps> = ({ data }: iProps) => {
  return (
    <>
      <h2 className="h1 with-tooltip">
        Dati amministrativi
        <Tooltip title="Testo di prova per tooltip">
          <img className="tooltip__info" src={Images.icoinfo.src} alt={Images.icoinfo.alt} />
        </Tooltip>
      </h2>
      <Row gutter={16}>
        <Col sm={12}>
          <Form.Item
            label="Ragione sociale"
            name="name"
            initialValue={data.name}
            rules={[{ required: true }]}
          >
            <Input placeholder="Inserisci la ragione sociale" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col sm={12}>
          <Form.Item
            label="Indirizzo"
            name="administrativeStreet"
            initialValue={data.administrativeStreet}
            rules={[{ required: true }]}
          >
            <Input placeholder="Inserisci l'indirizzo" />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <FormCity initialValue={data.administrativeCity} name="administrativeCity" />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col sm={12}>
          <Row gutter={16}>
            <Col span={12}>
              <FormProvince initialValue={data.administrativeState} name="administrativeState" />
            </Col>
            <Col span={12}>
              <FormZip initialValue={data.administrativeZip} name="administrativeZip" />
            </Col>
          </Row>
        </Col>
        <Col sm={12}>
          <FormPhone
            name="administrativePhone"
            initialValue={data.administrativePhone}
            label="Numero di telefono"
          />
        </Col>
      </Row>

      <hr className="div mt-10" />

      <Row gutter={16}>
        <Col sm={12}>
          <Form.Item
            label="Partita iva"
            name="vatNumber"
            initialValue={data.vatNumber}
            rules={[
              ({ getFieldValue }: any) => ({
                validator(rule, value) {
                  const { taxCode } = getFieldValue();

                  if (value) {
                    const regex = Config.regex.piva;
                    if (regex.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Deve contenere 11 cifre'));
                  }

                  if (!taxCode && !value) {
                    return Promise.reject(
                      new Error('I campi Partita iva o Codice fiscale devono essere compilati'),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Inserisci la partita IVA" />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item
            label="Codice fiscale"
            name="taxCode"
            initialValue={data.taxCode}
            rules={[
              ({ getFieldValue }: any) => ({
                validator(rule, value) {
                  const { vatNumber } = getFieldValue();

                  if (value) {
                    const regex = Config.regex.cf;
                    if (regex.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Non è un codice fiscale valido'));
                  }

                  if (!vatNumber && !value) {
                    return Promise.reject(
                      new Error('I campi Partita iva o Codice fiscale devono essere compilati'),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Inserisci il codice fiscale" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col sm={12}>
          <Form.Item
            label="Codice SDI"
            name="sdiCode"
            initialValue={data.sdiCode}
            // rules={[{ required: true}]}
            rules={[
              { required: false },
              ({ getFieldValue }: any) => ({
                validator(rule, value) {
                  if (value) {
                    const regex = Config.regex.sdi;
                    if (regex.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('Deve contenere 6 o 7 tra cifre o lettere maiuscole'),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Inserisci il codice SDI" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default ProfileEditAdmin;
